<template>
	<div class="grid dashboard">
    <div class="col-12 lg:col-12">
        <div class="grid m-0">
            <div class="col-12 xl:col-4">
                <div class="card p-0">
                    <div class="flex flex-column align-items-center pink-bgcolor white-color p-6 fs-large cursor-pointer" @click="onClick('/pflegebetten')">
                        <span>Pflegebetten</span>
                        <img src="layout/images/widgets/inpatient.svg" alt="feature-security">
                    </div>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="card p-0">
                    <div class="flex flex-column align-items-center pink-bgcolor white-color p-6 fs-large cursor-pointer" @click="onClick('/sauerstoffgeraete')">
                        <span>Sauerstoffgeräte</span>
                        <img src="layout/images/widgets/oxygen_tank.svg" alt="feature-security">
                    </div>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="card p-0">
                    <div class="flex flex-column align-items-center pink-bgcolor white-color p-6 fs-large cursor-pointer" @click="onClick('/rollstuehle')">
                        <span>Rollstühle</span>
                        <img src="layout/images/widgets/wheelchair_alt.svg" alt="feature-security">
                    </div>
                </div>
            </div>
          <div class="col-12 xl:col-12">
            <div class="card p-0">
              <div class="flex flex-column align-items-center pink-bgcolor white-color p-6 fs-large cursor-pointer" @click="onClick('/alleProdukte')">
                <span>Alle Produkte</span>
                <img src="layout/images/widgets/entry.svg" alt="feature-security">
              </div>
            </div>
          </div>
        </div>
    </div>







	</div>
</template>

<script>
// import store from "@/store";
	export default {
		name: "Home",
		data() {
			return {
            }
		},
        mounted() {
        },
		methods: {
      onClick(link){
        this.$router.push(link);
      }
		},
        computed: {
            isRTL() {
				return this.$appState.RTL;
			}
        }
	}
</script>

<style scoped>

</style>
